<template>
  <div>
    <router-link
      :to="{ name: 'admin.users.create' }"
      class="btn btn-theme btn-sm float-end"
      ><i class="lni lni-plus me-2"></i>Add New</router-link
    >
    <h3>User Management</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="!loading">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h4>Filter</h4>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date From</label>
              <date-picker input-class="form-control" v-model="filter.from" placeholder="Date From" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date To</label>
              <date-picker input-class="form-control" v-model="filter.to" placeholder="Date To" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Sort Order</label>
              <select v-model="filter.sort" class="form-control">
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Status</label>
              <select v-model="filter.status" class="form-control">
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Search</label>
              <input
                  type="text"
                  class="form-control"
                  placeholder="Search Name, Email, Phone Number"
                  v-model="filter.term"
                />
            </div>
          </div>
          <div class="col-md-8 text-end">
            <button class="btn btn-primary" @click.prevent="search"><i class="lni lni-search me-2"></i>Filter</button>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Role</th>
              <th>Joined</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) in users" :key="`user-${i}`">
              <td>{{ i + 1 }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone_number }}</td>
              <td>{{ user.role }}</td>
              <td>{{ user.joined }}</td>
              <td>
                <span
                  :class="`btn btn-sm btn-${
                    user.suspended ? 'danger' : 'success'
                  }`"
                  >{{ user.suspended ? "Suspended" : "Active" }}</span
                >
              </td>
              <td>
                <router-link
                  :to="{ name: 'admin.users.show', params: { id: user.id } }"
                  class="btn btn-theme btn-sm"
                  ><i class="lni lni-eye me-2"></i> Details</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="paginate" :perPage="10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  mounted() {
    this.filter.term = this.$route.query.search ? this.$route.query.search : ''
    this.pagination.page = this.$route.query.page ? this.$route.query.page : 1

    this.fetch();
  },

  data() {
    return {
      users: [],
      loading: true,
      pagination: { page: 1, records: 1 },
      filter: { sort: 'latest', from: null, to: null, term: '', status: 'active' }
    };
  },

  methods: {
    fetch() {
      this.loading = true
      let url = `/api/v1/admin/users?search=${this.filter.term}&page=${this.pagination.page}&sort=${this.filter.sort}&status=${this.filter.status}`;

      if(this.filter.from) {
        url += `&date_from=${moment(this.filter.from).format('YYYY-MM-DD')}`
      }

      if(this.filter.to) {
        url += `&date_to=${moment(this.filter.to).format('YYYY-MM-DD')}`
      }

      this.axios.get(url).then((response) => {
        this.users = response.data.users;
        this.pagination = response.data.pagination
        this.loading = false;
      });
    },

    search() {
      this.$router.push({ query: { search: this.filter.term }})
      this.fetch()
    },

    paginate() {
      this.$router.push({ query: { page: this.pagination.page }})
      this.fetch()
    }
  },
};
</script>
